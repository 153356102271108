import axios from 'axios'

const apiObject = {
  list ({ search, ordering, pageNum = 1, count = 10, filter = {}, search_field=null } = {}, ignoreErrorHandle = false) {
    var paramObject = Object.assign({}, { search, ordering, page: pageNum, count, search_field }, filter)
    return new Promise((resolve, reject) => {
      axios.get(this.url, {
        params: paramObject,
        ignoreErrorHandle: ignoreErrorHandle
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  get (id, ignoreErrorHandle = false) {
    return new Promise((resolve, reject) => {
      axios.get(`${this.url}${id}/`, {ignoreErrorHandle: ignoreErrorHandle})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  getHistorical (id, as_of=null, ignoreErrorHandle = false) {
    return new Promise((resolve, reject) => {
      axios.get(`${this.url}${id}/history/`, {params: {as_of}, ignoreErrorHandle: ignoreErrorHandle})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  save (obj, ignoreErrorHandle = false) {
    return new Promise((resolve, reject) => {
      var id = null
      if (obj.id)
        id = obj.id
      if (obj instanceof FormData)
        id = obj.get('id')
      let request = (id)
          ? axios.patch(`${this.url}${id}/`, obj, {ignoreErrorHandle: ignoreErrorHandle})
          : axios.post(this.url, obj, {ignoreErrorHandle: ignoreErrorHandle})
      request.then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  saveMany (objs) {
    const savePromises = objs.map(obj => {
      this.save(obj)
    })
    return axios.all(savePromises)
  }
}

const moveableObject = {
  move (obj, toIndex) {
    return new Promise((resolve, reject) => {
      axios.put(`${this.url}${obj.id}/move/`, { to: toIndex })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export {
  apiObject,
  moveableObject
}
