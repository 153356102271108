import axios from 'axios'

const documents = {
  url: '/documents/',
  reocr (document_id, ignoreErrorHandle = false) {
    return new Promise((resolve, reject) => {
      axios.post(`${this.url}${document_id}/reocr/`, {}, {ignoreErrorHandle: ignoreErrorHandle})
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
    })
  },
  download (document_id, ignoreErrorHandle = false) {
    return new Promise((resolve, reject) => {
      axios.get(`${this.url}${document_id}/`, {
        ignoreErrorHandle: ignoreErrorHandle,
        responseType: 'arraybuffer'
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  downloadHighlighted (document_id, ignoreErrorHandle = false){
    return new Promise((resolve, reject) => {
      axios.get(`${this.url}${document_id}/highlighted/`, {
        ignoreErrorHandle: ignoreErrorHandle,
        responseType: 'arraybuffer'
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        }         
        )
    })
  },
  downloadReferenced (document_id, ignoreErrorHandle = false){
    return new Promise((resolve, reject) => {
      axios.get(`${this.url}${document_id}/referenced/`, {
        ignoreErrorHandle: ignoreErrorHandle,
        responseType: 'arraybuffer'
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        }         
        )
    })
  },
}

export default {
  documents
}
