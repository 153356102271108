import { apiObject } from "./apiobject";
import axios from "axios";

const projectcase = {
    ...apiObject,
    url: '/projectcase/',
    downloadCSV({ search, ordering, pageNum = 1, count = 10, filter = {}, search_field=null } = {}) {
        var paramObject = Object.assign({}, { search, ordering, page: pageNum, count, search_field }, filter)
        return axios.get(`${this.url}csv/`, {
            params: paramObject,
            responseType: 'arraybuffer'
        })
    },
    history (id, ignoreErrorHandle = false) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.url}${id}/history/`, {ignoreErrorHandle: ignoreErrorHandle})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
      })
    },
    assign (project_id = null, user_id = null, cases = []) {
        return new Promise((resolve, reject) => {
            axios.post(`${this.url}assign/`,
                {
                    'project': project_id,
                    'user': user_id,
                    'cases': cases
                }
            )
                .then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
        })
    },
    downloadPacket (caseId) {
        return axios.get(`${this.url}${caseId}/packet/`, {
            responseType: 'arraybuffer'
        })
    },
  bulkIgnoreMatches (id, matchIds=[]) {
    return new Promise((resolve, reject) => {
      axios.post(`${this.url}${id}/bulkignore/`,{'match_ids': matchIds})
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error);
        });
    });
  },
}

export default {
    projectcase
}
