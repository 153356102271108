import axios from 'axios'
import { apiObject } from './apiobject'

const projects = {
    ...apiObject,
    url: '/project/',
    create (name = '') {
      return new Promise((resolve, reject) => {
        axios.post(this.url, { 'name': name })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addKeywords(project_id, keywords, ignoreErrorHandle = false) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.url}${project_id}/addkeywords/`, {'keywords': keywords}, {ignoreErrorHandle: ignoreErrorHandle})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
        ;
      });
    },
    submitDocument (project_id, document_id, ignoreErrorHandle = false) {
        return new Promise((resolve, reject) => {
        axios.post(`${this.url}${project_id}/${document_id}/submit/`, {}, {ignoreErrorHandle: ignoreErrorHandle})
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
        })
    },
    documentHistory (project_id, document_id, ignoreErrorHandle = false) {
        return new Promise((resolve, reject) => {
        axios.get(`${this.url}${project_id}/${document_id}/history/`, {ignoreErrorHandle: ignoreErrorHandle})
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            reject(error)
        })
        })
    },
    revertDocumentQCStatus (project_id, document_id, ignoreErrorHandle=false) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.url}${project_id}/${document_id}/revertQC/`, {}, {ignoreErrorHandle: ignoreErrorHandle})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
              reject(error)
          })
      });
    },
    addCasesToProject (project_id = null, case_nums = []) {
        return new Promise((resolve, reject) => {
            axios.post(`${this.url}addcases/`,
            {
                'project': project_id,
                'cases': case_nums
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    addEarlySubmissionReason (project_id = null, reason = '') {
        return new Promise((resolve, reject) => {
            axios.post(
              `${this.url}${project_id}/addearlysubmissionreason/`,
              { 'reason': reason }
            )
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    addReviewResultOption (project_id = null, result = '') {
        return new Promise((resolve, reject) => {
            axios.post(
              `${this.url}${project_id}/addreviewresultoption/`,
              { 'result': result }
            )
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
}

export default {
    projects
}
