import { apiObject } from "./apiobject"
import axios from 'axios'

const reports = {
    ...apiObject,
    url: '/reports/',
    getProjectCaseReport (id) {
      return axios.get(`${this.url}pc/${id}/`, {
        responseType: 'arraybuffer'
      })
    }
}

export default {
    reports
}