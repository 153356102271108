import axios from 'axios'

const cases = {
  url: '/cases/',
  upload (case_id, formPostData, onUploadProgress=null, ignoreErrorHandle = false) {
    return axios.post(`${this.url}${case_id}/upload/`,
      formPostData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: onUploadProgress,
        ignoreErrorHandle: ignoreErrorHandle
      }
    )
  },
}

export default {
  cases
}
