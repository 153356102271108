import { apiObject } from './apiobject'

const pages = {
  ...apiObject,
  url: '/pages/',
}

export default {
  pages
}
