//import axios from 'axios'
import { apiObject } from './apiobject'

const references = {
    ...apiObject,
    url: '/references/',
}

export default {
    references
}