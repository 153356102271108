import references from './references'
import project from './project'
import projectcase from './projectcase'
import users from './users'
import documents from './documents'
import pages from './pages'
import reports from './reports'
import cases from './cases'
import referencetypes from './referencetypes'
import axios from 'axios'

const user = {
    url: '/user/',
    get (ignoreErrorHandle = false) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.url}/`, {ignoreErrorHandle: ignoreErrorHandle})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
      })
    },
}


export default {
    references,
    project,
    projectcase,
    users,
    documents,
    pages,
    user,
    cases,
    reports,
    referencetypes,
}
