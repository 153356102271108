//import axios from 'axios'
import { apiObject } from './apiobject'
import { getPaginatedResults } from '@/store/index.js'

const referencetypes = {
    ...apiObject,
    url: '/referencetypes/',
    getProjectReferenceTypes (project_id) {
      return new Promise((resolve) => {
        getPaginatedResults(this.url, { 'project_id': project_id }, resolve);
      });
    },
}

export default {
    referencetypes
}
