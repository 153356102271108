import { apiObject } from "./apiobject";
import axios from "axios";

const users = {
    ...apiObject,
    url: '/users/',
    changePassword ( userId, password = '', currentPassword = '') {
        return new Promise((resolve, reject) => {
            axios.post(`/users/${userId}/resetpassword/`, {password: password, current_password: currentPassword})
                .then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
        })
    }
}

export default {
    users
}